let messages = {
  'ru': {
    'Loading...': 'Загрузка...',
    'CATALOG_BUY': 'Купить',
    'CATALOG_NOT_AVAILABLE': 'нет на складе',
    'ECONOMY_INFO_MESSAGE': 'Скидка #ECONOMY#',
    'TITLE_ERROR': 'Ошибка',
    'TITLE_BASKET_PROPS': 'Свойства товара, добавляемые в корзину',
    'BASKET_UNKNOWN_ERROR': 'Ошибка при добавлении товара в корзину',
    'BTN_SEND_PROPS': 'Выбрать',
    'BTN_MESSAGE_BASKET_REDIRECT': 'Перейти в корзину',
    'BTN_MESSAGE_CLOSE': 'Закрыть',
    'BTN_MESSAGE_CLOSE_POPUP': 'Продолжить покупки',
    'ADD_CART_BUTTON_CONTINUE': 'Продолжить покупки',
    'ADD_CART_QUANTITY_LIMITED': 'Количество товара ограничено.',
    'TITLE_SUCCESSFUL': 'Товар добавлен в корзину',
    'ADD_CART_STATUS_OK': 'Товар добавлен в корзину',
    'COMPARE_MESSAGE_OK': 'Товар добавлен в список сравнения',
    'COMPARE_UNKNOWN_ERROR': 'При добавлении товара в список сравнения произошла ошибка',
    'COMPARE_TITLE': 'Сравнение товаров',
    'BTN_MESSAGE_COMPARE_REDIRECT': 'Перейти в список сравнения',
    'PRODUCT_GIFT_LABEL': 'Подарок',
    'PRICE_TOTAL_PREFIX': 'на сумму',
    'ADD_CART_PRICE_TITLE': 'Цена за ед',
    'ADD_CART_QUANTITY_TITLE': 'Количество',
    'ADD_CART_TOTAL_TITLE': 'Итого',
    'ADD_CART_ARTICLE': 'код товара',
    'ADD_CART_BUTTON_ORDER': 'Оформить заказ',
    'TITLE_SALE': 'Скидка',
    'CATALOG_MORE': 'Еще'
  },
  'ua': {
    'Loading...': 'Завантаження...',
    'CATALOG_BUY': 'Купити',
    'CATALOG_NOT_AVAILABLE': 'немає на складі',
    'ECONOMY_INFO_MESSAGE': 'Знижка #ECONOMY#',
    'TITLE_ERROR': 'Помилка',
    'TITLE_BASKET_PROPS': 'Властивості товару, що додаються в кошик',
    'BASKET_UNKNOWN_ERROR': 'Помилка при додаванні товару в кошик',
    'BTN_SEND_PROPS': 'Вибрати',
    'BTN_MESSAGE_BASKET_REDIRECT': 'Перейти до кошика',
    'BTN_MESSAGE_CLOSE': 'Закрити',
    'BTN_MESSAGE_CLOSE_POPUP': 'Продовжити покупки',
    'ADD_CART_BUTTON_CONTINUE': 'Продовжити покупки',
    'ADD_CART_QUANTITY_LIMITED': 'Кількість товару обмежена.',
    'TITLE_SUCCESSFUL': 'Товар додано в кошик',
    'ADD_CART_STATUS_OK': 'Товар додано в кошик',
    'COMPARE_MESSAGE_OK': 'Товар додано до списку порівняння',
    'COMPARE_UNKNOWN_ERROR': 'Під час додавання товару до списку порівняння сталася помилка',
    'COMPARE_TITLE': 'Порівняння товарів',
    'BTN_MESSAGE_COMPARE_REDIRECT': 'Перейти до списку порівняння',
    'PRODUCT_GIFT_LABEL': 'Подарунок',
    'PRICE_TOTAL_PREFIX': 'на суму',
    'ADD_CART_PRICE_TITLE': 'Ціна за од',
    'ADD_CART_QUANTITY_TITLE': 'Кількість',
    'ADD_CART_TOTAL_TITLE': 'Всього',
    'ADD_CART_ARTICLE': 'код товару',
    'ADD_CART_BUTTON_ORDER': 'Оформити замовлення',
    'TITLE_SALE': 'Знижка',
    'CATALOG_MORE': 'Ще'
  },
  'en': {
    'Loading...': 'Loading...',
    'CATALOG_BUY': 'Buy',
    'CATALOG_NOT_AVAILABLE': 'out of stock',
    'ECONOMY_INFO_MESSAGE': 'Discount #ECONOMY#',
    'TITLE_ERROR': 'Error',
    'TITLE_BASKET_PROPS': 'Product properties added to cart',
    'BASKET_UNKNOWN_ERROR': 'Error when adding product to cart',
    'BTN_SEND_PROPS': 'Select',
    'BTN_MESSAGE_BASKET_REDIRECT': 'Go to cart',
    'BTN_MESSAGE_CLOSE': 'Close',
    'BTN_MESSAGE_CLOSE_POPUP': 'Continue shopping',
    'ADD_CART_BUTTON_CONTINUE': 'Continue shopping',
    'ADD_CART_QUANTITY_LIMITED': 'Product quantity is limited.',
    'TITLE_SUCCESSFUL': 'Product added to cart',
    'ADD_CART_STATUS_OK': 'Product added to cart',
    'COMPARE_MESSAGE_OK': 'Product added to comparison list',
    'COMPARE_UNKNOWN_ERROR': 'An error occurred while adding the product to the comparison list',
    'COMPARE_TITLE': 'Product comparison',
    'BTN_MESSAGE_COMPARE_REDIRECT': 'Go to comparison list',
    'PRODUCT_GIFT_LABEL': 'Gift',
    'PRICE_TOTAL_PREFIX': 'total amount',
    'ADD_CART_PRICE_TITLE': 'Price per unit',
    'ADD_CART_QUANTITY_TITLE': 'Quantity',
    'ADD_CART_TOTAL_TITLE': 'Total',
    'ADD_CART_ARTICLE': 'product code',
    'ADD_CART_BUTTON_ORDER': 'Checkout',
    'TITLE_SALE': 'Discount',
    'CATALOG_MORE': 'More'
  }
}

export default messages