'use strict'

import React from 'react'
import './LoadingBlock.css'

function LoadingBlock () {
  return (
    <div className="preloader-wrapper">
      <div className="preloader"/>
    </div>
  )
}

export default LoadingBlock