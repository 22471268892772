'use strict'

import React, { useCallback, useState} from 'react'
import { application, eventEmitter, lang, authRegister, esputnikTracker } from '../../common'
import axios from 'axios'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'
// Импорты стилей Swiper
import 'swiper/css'
import 'swiper/css/pagination'

function ProductCard (props) {

	const productAvailable = props.catalogAvailable === 'Y' ? 1 : 0

	const image = props.images && props.images.length > 0 ? props.images[0] : {}

	// Установка actualItem в зависимости от наличия и заполненности props.offers
	const actualItem = props.offers && props.offers.length > 0 ? props.offers[0] : props

	const currentPrice = props.prices.PRICE ? Number(props.prices.PRICE) : 0;
	const discountPrice = props.prices.DISCOUNT_PRICE > 0 ? props.prices.DISCOUNT_PRICE : 0;

	const detailPageUrl = (lang !== 'ru' && !props.detailPageUrl.startsWith(`/${lang}`))
		? `/${lang}${props.detailPageUrl}`
		: props.detailPageUrl

	// Компонент для одной звезды
	const StarIcon = () => (
			<div className="rate__star">
				<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="13" height="12" viewBox="0 0 13 12">
					<path fillRule="evenodd" d="M12.435,4.097 L8.854,3.769 C8.629,3.749 8.434,3.609 8.341,3.400 L7.059,0.382 C6.854,-0.116 6.125,-0.116 5.920,0.382 L4.648,3.400 C4.566,3.609 4.361,3.749 4.135,3.769 L0.554,4.097 C0.021,4.147 -0.195,4.795 0.205,5.143 L2.904,7.444 C3.078,7.594 3.150,7.813 3.099,8.032 L2.288,11.259 C2.165,11.767 2.729,12.185 3.201,11.917 L6.187,10.213 C6.382,10.104 6.618,10.104 6.813,10.213 L9.798,11.917 C10.270,12.185 10.834,11.777 10.711,11.259 L9.911,8.032 C9.860,7.813 9.932,7.594 10.106,7.444 L12.804,5.143 C13.194,4.795 12.968,4.147 12.435,4.097 Z" className="cls-1"></path>
				</svg>
			</div>
	);

	const renderStars = () => {
		const stars = [];
		for (let i = 0; i < 5; i++) {
			stars.push(<StarIcon key={i} />);
		}
		return stars;
	}

	const updateWishlist = (productId) => {
		// Получаем элемент wishlistItem из localStorage
		let wishlistItem = localStorage.getItem('wishlistItem');

		// Если wishlistItem существует, парсим его, иначе создаем пустой массив
		wishlistItem = wishlistItem ? JSON.parse(wishlistItem) : [];

		// Проверяем, есть ли productId в массиве wishlistItem
		const index = wishlistItem.indexOf(productId);

		if (index !== -1) {
			// Если productId найден, удаляем его из массива
			wishlistItem.splice(index, 1);
		} else {
			// Если productId не найден, добавляем его в массив
			wishlistItem.push(productId);
		}

		// Сохраняем обновленный массив wishlistItem обратно в localStorage
		localStorage.setItem('wishlistItem', JSON.stringify(wishlistItem));
	};

	const add2Wishlist = useCallback((event) => {

		if (props.isAuthorize == 'N') {
			let authButton = document.querySelector('[data-container="user"][data-use="auth"]')
			if (authButton) {
				authButton.click() // Имитация нажатия
			} else {
				console.error('Auth button not found!')
			}
		} else {
			const element = event.currentTarget
			const productId = element.getAttribute('data-product-id')
			const productPrice = element.getAttribute('data-price')
			const productAvailable = element.getAttribute('data-available')

			const config = { headers: { 'X-Requested-With': 'XMLHttpRequest' } }

			let formData = new FormData()
			formData.append('sessid', application.getSessionId())
			formData.append('productId', productId)

			axios.post('/api/wishlist/wishlistAction/', formData, config).then(response => {
				if (response.data.action === 'add') {
					element.classList.add('active')

					// Esputnik Tracker
					esputnikTracker.AddToWishlist(
						productId,
						productPrice,
						productAvailable)
				} else if (response.data.action === 'delete') {
					element.classList.remove('active')
				}
				updateWishlist(productId)

				const wishlistBlock = document.querySelector(
					'.header-links__item--wishlist')
				if (wishlistBlock) {
					const quantitySpan = wishlistBlock.querySelector('span.wishlist-quantity')
					if (quantitySpan) {
						// Установить содержимое span в значение response.data.total, если оно не пустое и не равно 0
						if (response.data.total && response.data.total !== 0) {
							quantitySpan.textContent = response.data.total
							quantitySpan.style.display = 'flex'
						} else {
							quantitySpan.textContent = ''
							quantitySpan.style.display = 'none'
						}
					}
				}
				const mobileMenuWishlist = document.querySelector('.tap-bar-item-wishlist-count')
				if (mobileMenuWishlist) {
					// Установить содержимое span в значение response.data.total, если оно не пустое и не равно 0
					if (response.data.total && response.data.total !== 0) {
						mobileMenuWishlist.textContent = response.data.total
						mobileMenuWishlist.style.display = 'flex'
					} else {
						mobileMenuWishlist.textContent = ''
						mobileMenuWishlist.style.display = 'none'
					}
				}
			}).catch(e => {
				console.log(e)
			})
		}
	}, [])

	function onClick (event) {
		event.preventDefault()
		window.location.href = product_url() // Переходим по полученной ссылке
	}

	const product_url = () => {
		let es_query = (props.productImpressionData !== undefined &&
			props.productImpressionData.hasOwnProperty('container_type')) ?
			`?sc_content=${props.productImpressionData.container_type}` :
			''

		return detailPageUrl + es_query
	}

	const handleBuyButtonClick = () => {
		// Подготавливаем данные для попапа и вызываем функцию родителя
		const popupData = {
			isActive: true,
			product: {
				id: actualItem,
				name: props.name,
				image: image.SRC,
				price: currentPrice,
				discountPrice: discountPrice,
				code: props.displayProperties[30].VALUE_ID,
				isAvailable: props.catalogAvailable === "Y",
			},
			onClose: () => {
				// Функция для закрытия попапа
				props.onShowAddToCartPopup(prevData => ({
					...prevData,
					isActive: false
				}));
			},
			onCheckout: () => {
				// Функция для перехода к оформлению заказа
				window.location.href = '/personal/order/make/';
			}
		};

		props.onShowAddToCartPopup(popupData);
		eventEmitter.emit('OnBasketChange');
	};

	const renderProductImages = () => {
		if (!props.images || props.images.length === 0) {
			return null;
		}

		return (
				<Swiper
						modules={[Pagination]}
						pagination={{
							clickable: true
						}}
						lazy={true}
						slidesPerView={1}
						spaceBetween={10}
						breakpoints={{
							640: {
								slidesPerView: 1,
								spaceBetween: 20
							},
							768: {
								slidesPerView: 1,
								spaceBetween: 30
							},
							1024: {
								slidesPerView: 1,
								spaceBetween: 40
							}
						}}
						className="w-full h-full"
				>
				{props.images.slice(0, 6).map((image, index) => (
					<SwiperSlide key={index} className="flex items-center justify-center">
							<img
									className="w-full object-cover h-auto"
									src={image.SRC}
									width={image.WIDTH}
									height={image.HEIGHT}
									alt={`${props.name} - ${index + 1}`}
									title={`${props.name}`}
									onClick={() => window.location.href = detailPageUrl}
							/>
					</SwiperSlide>
				))}
				</Swiper>
		);
	};

	return (
			<>
		<div className="sm:w-auto max-sm:flex-shrink-0 max-sm:snap-center flex flex-col justify-between bg-white overflow-hidden"
		>
			<div className="overflow-hidden relative">
				<div className="w-full h-full p-5 block border-solid border-[#e1e6e9] border product__image">
					{renderProductImages()}
				</div>
				<div className="product__image-inner">
					{props.prices.DISCOUNT_PRICE && props.prices.DISCOUNT_PRICE > 0 ? (
						<div
							className="product-label product-label--sale">Sale</div>
					) : props.isNew === 'Y' ? (
							<div
									className="product-label product-label--new">New</div>
					) : props.topProdazh === 'Y' ? (
									<div className="product-label product-label--top">Top</div>
						) : null}
				</div>

				<div
						className={`available__label ${props.catalogAvailable === 'N' ? 'not__available' : 'available'}`}>
					{props.catalogAvailable === 'N' ? (
						<>
							<svg className="w-4 h-4">
								<use xmlnsXlink="http://www.w3.org/1999/xlink"
									 xlinkHref="/local/templates/staleks/images/sprite.svg#close"></use>
							</svg>
							{props.getMessage('CATALOG_NOT_AVAILABLE')}
						</>
					) : (
						<>
							<svg className="w-4 h-4">
								<use xmlnsXlink="http://www.w3.org/1999/xlink"
										 xlinkHref="/local/templates/staleks/images/sprite.svg#chek"></use>
							</svg>
							{props.getMessage('CATALOG_AVAILABLE')}
						</>
					)}
				</div>

				{props.ukLabel?.SRC && (
					<img
						src={props.ukLabel.SRC}
						className="absolute bottom-6 right-2 w-[40px] h-[26px] z-[1]"
						alt="Ukraine flag"
						title="Ukraine flag"
					/>
				)}
			</div>

			<div className="flex flex-col justify-between">

				<div className="product-page__reviews mb-[10px]">
					<a
							href={detailPageUrl}
							className="product-page__reviews-link scroll-btn">
						({props.productRating?.RATING_COUNT || 0})
					</a>
					<div className="product__rate">
						<div className="rate" data-rate={props.productRating?.RATING || 0}>
							{renderStars()}
						</div>
					</div>
				</div>

				<div className="product__info-top">
					<div className="product__title">
						<a href={product_url()}
							 onClick={onClick}
							 tabIndex="0">{props.name}</a>
					</div>
				</div>

				<div className="product__price-box">
					<div className="product__price">
						{props.prices.DISCOUNT_PRICE && props.prices.DISCOUNT_PRICE > 0 ? (
								<>
									<div
											className="text-[#333] text-[18px] mr-2">{props.prices.DISCOUNT_PRICE_FORMATED}</div>
									<div
											className="text-sm text-gray-500 line-through">{props.prices.PRICE_FORMATED}</div>
								</>
						) : (
								<>
									<div
											className="text-[#333] text-[18px]">{props.prices.PRICE_FORMATED}</div>
									<div className="hidden"></div>
								</>
						)}
					</div>
				</div>

				<div className="product__buttons" data-entity="buttons-block">
					<div className="buy-btn" onClick={handleBuyButtonClick}>
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 16 22">
								<path d="M1676,59.016l-1.15-14.185a0.568,0.568,0,0,0-.55-0.553h-2.35a3.963,3.963,0,1,0-7.9,0h-2.35a0.568,0.568,0,0,0-.55.553L1660,59.016v0.054A2.965,2.965,0,0,0,1663,62h10a2.971,2.971,0,0,0,3-2.929V59.016Zm-8-17.8a3,3,0,0,1,2.85,3.064h-5.7A3,3,0,0,1,1668,41.214Zm5,19.571h-10a1.823,1.823,0,0,1-1.9-1.687l1.1-13.6h1.85v1.845a0.553,0.553,0,1,0,1.1,0V45.5h5.7v1.845a0.575,0.575,0,0,0,.55.607,0.582,0.582,0,0,0,.55-0.607V45.5h1.85l1.1,13.6A1.829,1.829,0,0,1,1673,60.784Z" transform="translate(-1660 -40)"></path>
							</svg>
							<div className="added">
							<svg xmlns="http://www.w3.org/2000/svg" width="8.469" height="6.82" viewBox="0 0 8.469 6.82">
								<path d="M832.348,1759.84a0.343,0.343,0,0,1,0-.48l0.456-.47a0.3,0.3,0,0,1,.457,0l0.033,0.03,1.794,2.01a0.167,0.167,0,0,0,.228,0l4.371-4.73h0.033a0.3,0.3,0,0,1,.456,0l0.457,0.48a0.332,0.332,0,0,1,0,.47h0l-5.219,5.65a0.311,0.311,0,0,1-.457,0l-2.544-2.86Z" transform="translate(-832.25 -1756.09)"></path>
							</svg>
						</div>
						</span> {props.getMessage('CT_BCS_TPL_MESS_BTN_BUY')}
					</div>

					<div className={`wishlist-btn ${props.inWishlist ===
							'Y' ? 'active' : ''}`}
							 data-product-id={props.id}
							 data-price={currentPrice}
							 data-available={productAvailable}
							 onClick={add2Wishlist}
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20">
							<path d="M1628.14,43.856a6.41,6.41,0,0,0-9,.005l-0.63.622-0.64-.631a6.417,6.417,0,0,0-9.01-.01,6.251,6.251,0,0,0,.01,8.908l9.16,9.057a0.662,0.662,0,0,0,.93,0l9.17-9.042A6.256,6.256,0,0,0,1628.14,43.856Zm-0.93,7.991-8.72,8.585-8.69-8.6a4.968,4.968,0,0,1-.01-7.065,5.1,5.1,0,0,1,7.15.005l1.1,1.09a0.661,0.661,0,0,0,.93,0l1.09-1.081a5.1,5.1,0,0,1,7.15,0A4.985,4.985,0,0,1,1627.21,51.847Z" transform="translate(-1607 -42)"></path>
						</svg>
						<span className="added">
							<svg xmlns="http://www.w3.org/2000/svg" width="8.469" height="6.82" viewBox="0 0 8.469 6.82">
								<path d="M832.348,1759.84a0.343,0.343,0,0,1,0-.48l0.456-.47a0.3,0.3,0,0,1,.457,0l0.033,0.03,1.794,2.01a0.167,0.167,0,0,0,.228,0l4.371-4.73h0.033a0.3,0.3,0,0,1,.456,0l0.457,0.48a0.332,0.332,0,0,1,0,.47h0l-5.219,5.65a0.311,0.311,0,0,1-.457,0l-2.544-2.86Z" transform="translate(-832.25 -1756.09)"></path>
							</svg>
						</span>
					</div>
				</div>
			</div>
		</div>
			</>
	)
}

export default ProductCard