import {esputnikTracker} from "./common";
import BannerSlider from 'devSrc/home/bannerSlider/BannerSlider';
import {PromotionalBanner} from 'devSrc/home/promotionalBanner';
import {ServiceSlider} from 'devSrc/home/serviceSlider';
import CategoryTabs from "devSrc/lib/CategoryTabs/CategoryTabs";
import Product from './products/Product/Product';
import 'devSrc/home/style.css';
import 'devSrc/home/rootSectionList.css';
import {createRoot} from 'react-dom/client';
import React, {lazy, Suspense} from 'react'
import ProductsListSlider from './components/ProductsListSlider/ProductsListSlider';

//Banner
let banner_wrapper = document.querySelector('#banner_sl');
if (banner_wrapper instanceof HTMLElement) {
	new BannerSlider(banner_wrapper).init();
}

// Новинки
/*const new_products_wrapper = document.querySelector('.new_products_js');

if (new_products_wrapper instanceof HTMLElement) {
	(new CategoryTabs(new_products_wrapper)).initSimpleSlider();
}*/

// Топ продаж
/*const top_products_wrapper = document.querySelector('.top_products_js');

if (top_products_wrapper instanceof HTMLElement) {
	(new CategoryTabs(top_products_wrapper)).initSimpleSlider();
}*/

// Скидки
/*const sale_products_wrapper = document.querySelector('.sale_products_js');

if (sale_products_wrapper instanceof HTMLElement) {
	(new CategoryTabs(sale_products_wrapper)).initSimpleSlider();
}*/

//Promotional banner
let promBannerBlockNode = document.querySelector('#banner_pr');
if (promBannerBlockNode instanceof HTMLElement) {
	let params = JSON.parse(atob(promBannerBlockNode.dataset.params));
	params.node = promBannerBlockNode;
	new PromotionalBanner(params);
}

let elements = [].slice.apply(document.querySelectorAll('[data-entity="item"]'));
elements.forEach(item => {
	let params = JSON.parse(atob(item.dataset.itemParams));
	// let catalogItem = new JCCatalogItem(params);
	let catalogItem = new Product(params);
	let timerNode = document.querySelector(`[data-productId="${params['AREA_ID']}"]`);
	if ((timerNode instanceof HTMLElement)
			&& timerNode.dataset.hasOwnProperty('params')) {
		let timerParams = JSON.parse(atob(timerNode.dataset.params));
		catalogItem.setTimer(timerParams['timer'], timerParams['type']);
	}
});

//Slider block 'main-service__slider'
let pagePagination = document.getElementById('main-service__inner')
if (pagePagination instanceof HTMLElement) {
	let root = createRoot(pagePagination);
	root.render(<ServiceSlider {...shopServiceSlider}/>);
}

// //Slider block 'main-service__slider'
// let pagePagination = document.getElementById('main-service__inner')
// if (pagePagination instanceof HTMLElement) {
// 	let root = createRoot(pagePagination);
// 	root.render(<ServiceSlider {...shopServiceSlider}/>);
// }

// Блок новинки
let newProductListNode = document.querySelector('.areanewProductList')
if (newProductListNode instanceof HTMLElement) {
	const newProductListRoot = createRoot(newProductListNode)
	newProductListRoot.render(<Suspense fallback={<></>}><ProductsListSlider {...newProductList}/></Suspense>)
}

// Блок распродажа
let saleProductListNode = document.querySelector('.areasaleProductList')
if (saleProductListNode instanceof HTMLElement) {
	const saleProductListRoot = createRoot(saleProductListNode)
	saleProductListRoot.render(<Suspense fallback={<></>}><ProductsListSlider {...saleProductList}/></Suspense>)
}

// Блок топ продаж
let topProductListNode = document.querySelector('.areatopProductList')
if (topProductListNode instanceof HTMLElement) {
	const topProductListRoot = createRoot(topProductListNode)
	topProductListRoot.render(<Suspense fallback={<></>}><ProductsListSlider {...topProductList}/></Suspense>)
}

esputnikTracker.MainPage()