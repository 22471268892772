import React, { useState, useEffect, useRef } from 'react'
import ProductCard from './ProductCard'
import LoadingBlock from '../loadingBlock'
// Импортируем Swiper и необходимые модули
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import AddToCartPopup from '../addToCartPopup';

// Импортируем стили
import 'swiper/css';
import 'swiper/css/navigation';


function ProductsListSlider (props) {

	const swiperRef = useRef(null);

	const productsList = Object.values(props.items ?? {}) || [];

	const [state, setState] = useState({
		products: productsList ?? [],
		messages: props.messages ?? {},
		fuserId: props.fuserId ?? 0,
		isAuthorize: props.isAuthorize ?? {},
	})

	const [showPopup, setShowPopup] = useState(false);
	const [popupData, setPopupData] = useState({
		isActive: false,
		product: {
			id: '',
			name: '',
			image: '',
			price: 0,
			discountPrice: 0,
			code: '',
			isAvailable: true,
		},
		onClose: () => {},
		onCheckout: () => {}
	});

	const handleShowAddToCartPopup = (data) => {
		setPopupData(data);
		setShowPopup(true);
	};

	function getMessage (code) {
		return state.hasOwnProperty('messages') && state.messages.hasOwnProperty(code) ?
			state.messages[code] : `lang message: ${code} not defined`
	}

	return (
    <>
      {state.products.length > 0 ? (
        <>
          <div className="custom-navigation">
            <div className="custom-prev-button relative cursor-pointer">
              <div className="bottom-auto top-[4px] h-[25px] text-black right-[35px] left-auto absolute w-[calc(var(--swiper-navigation-size)/44*27)] z-10 flex items-center justify-center">
                <svg
                  width="11"
                  height="20"
                  viewBox="0 0 11 20"
                  className="w-full h-full object-contain origin-center"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.38296 20.0762C0.111788 19.805 0.111788 19.3654 0.38296 19.0942L9.19758 10.2796L0.38296 1.46497C0.111788 1.19379 0.111788 0.754138 0.38296 0.482966C0.654131 0.211794 1.09379 0.211794 1.36496 0.482966L10.4341 9.55214C10.8359 9.9539 10.8359 10.6053 10.4341 11.007L1.36496 20.0762C1.09379 20.3474 0.654131 20.3474 0.38296 20.0762Z"
                    fill="currentColor"
                    transform="rotate(180, 5.5, 10)"
                  />
                </svg>
              </div>
            </div>

            <div className="custom-next-button relative cursor-pointer">
              <div className="bottom-auto top-[25px] h-[25px] text-black right-0 left-auto absolute w-[calc(var(--swiper-navigation-size)/44*27)] mt-[calc(0px-(var(--swiper-navigation-size)/2))] z-10 flex items-center justify-center">
                <svg
                  width="11"
                  height="20"
                  viewBox="0 0 11 20"
                  className="w-full h-full object-contain origin-center"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.38296 20.0762C0.111788 19.805 0.111788 19.3654 0.38296 19.0942L9.19758 10.2796L0.38296 1.46497C0.111788 1.19379 0.111788 0.754138 0.38296 0.482966C0.654131 0.211794 1.09379 0.211794 1.36496 0.482966L10.4341 9.55214C10.8359 9.9539 10.8359 10.6053 10.4341 11.007L1.36496 20.0762C1.09379 20.3474 0.654131 20.3474 0.38296 20.0762Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
          </div>

          <Swiper
            slidesPerView={2}
            spaceBetween={20}
            modules={[Navigation]}
            navigation={{
              nextEl: '.custom-next-button',
              prevEl: '.custom-prev-button',
							disabledClass: 'swiper-button-disabled'
						}}
						onSwiper={(swiper) => {
							swiperRef.current = swiper;

							// Установим начальное состояние кнопок
							if (swiper.isBeginning) {
								document.querySelector('.custom-prev-button').classList.add('swiper-button-disabled');
							}
							if (swiper.isEnd) {
								document.querySelector('.custom-next-button').classList.add('swiper-button-disabled');
							}
						}}
						onSlideChange={(swiper) => {
							// Обновляем состояние кнопок при смене слайдов
							const prevBtn = document.querySelector('.custom-prev-button');
							const nextBtn = document.querySelector('.custom-next-button');

							if (swiper.isBeginning) {
								prevBtn.classList.add('swiper-button-disabled');
							} else {
								prevBtn.classList.remove('swiper-button-disabled');
							}

							if (swiper.isEnd) {
								nextBtn.classList.add('swiper-button-disabled');
							} else {
								nextBtn.classList.remove('swiper-button-disabled');
							}
						}}
						breakpoints={{
              320: { slidesPerView: 2 },
              640: { slidesPerView: 3 },
              900: { slidesPerView: 4 },
            }}
            className="main-page-products-slider main-products__slider"
          >
            {state.products.map(product => (
              <SwiperSlide key={`viewed-${product.ID}`}>
                <ProductCard
                  {...product}
                  messagesList={state.messages}
                  getMessage={getMessage}
                  fuserId={state.fuserId}
                  isAuthorize={state.isAuthorize}
									onShowAddToCartPopup={handleShowAddToCartPopup}
								/>
              </SwiperSlide>
            ))}
          </Swiper>
					{showPopup && popupData && (
							<AddToCartPopup
									isActive={popupData.isActive}
									productId={popupData.product.id}
									productName={popupData.product.name}
									imageUrl={popupData.product.image}
									productCode={popupData.product.code}
									isAvailable={popupData.product.isAvailable}
									price={popupData.product.price}
									discountPrice={popupData.product.discountPrice}
									onClose={popupData.onClose}
									onCheckout={popupData.onCheckout}
									fuserId={props.fuserId}
							/>
					)}

				</>
      ) : (
        <LoadingBlock />
      )}
    </>
  );
}

export default ProductsListSlider